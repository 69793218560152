.missionBrowser {
  background-color: $cadet-color-3;
  min-width: 600px;
}

.missionLoading {
  height: 100%;
  display: flex;
  background-color: $cadet-color-1;
  flex-direction: column;
  flex: 1 1 100%;
}

.SideContentMissionEditor {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;

  .workspace {
    .ControlBar {
      .ControlBar_editingWorkspace {
        width: 0;
      }
    }
  }

  .SideContentMissionEditorRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .SideContentMissionEditorLabelColumn {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 0.4;
  }

  .SideContentMissionEditorOptionColumn {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }
}
