/*
 * Style for all navigation bars.
 */
.NavigationBar {
  width: 100%;
  flex: 0 0 auto;
  padding: 0px 10px;

  .thin-divider {
    /* Used to lessen the space between icon-only buttons (on small displays). */
    margin-left: 0px;
    margin-right: 0px;
  }

  .navbar-button-text {
    /* This provides some space between the icon and the text on larger displays. */
    margin-left: 0.25rem;
  }
}

a.NavigationBar__link {
  color: inherit;
  text-decoration: none;

  > :first-child {
    /*
     * Keep an even margin around the icon (instead of a larger right margin) to
     * allow for even spacing when viewed on smaller viewports.
     */
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }

  > .navbar-button-text {
    display: flex;
  }
}

a.NavigationBar__link__mobile {
  color: inherit;
  text-decoration: none;

  > :first-child {
    margin-right: 0.5rem;
  }
}

/*
 * Style for the navigation bar of the entire site (used in Application)
 */
.primary-navbar {
  .#{$ns}-button {
    font-weight: 600;
  }

  .#{$ns}-navbar-heading {
    text-transform: uppercase;
    padding: 0 0 3.5px 4px;
  }
}

/*
 * Style for the navigation bar of particular sections (e.g Academy). This is the
 * "nested" navigation bar under the primary one.
 */
.secondary-navbar {
  height: 40px;

  .#{$ns}-navbar-group {
    height: 40px;
  }

  .#{$ns}-button {
    /* Reduce emphasis on secondary navbar buttons */
    text-transform: initial;
    font-weight: 250;
  }
}

.desktop-navbar-popover {
  .#{$ns}-popover2-arrow-fill {
    fill: $cadet-color-5 !important;
  }

  .#{$ns}-popover2-content {
    background: $cadet-color-5 !important;
    padding: 0;

    .#{$ns}-navbar {
      background: $cadet-color-5;
      box-shadow: none;

      .#{$ns}-button {
        color: $cadet-color-1;

        .navbar-button-text {
          margin-left: 0.25rem;
        }
      }
    }
  }
}
