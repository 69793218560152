.AchievementDashboard {
  background: black url(#{$achievement-assets}/background.png) center/cover no-repeat fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 100%;

  .achievement-overview {
    $default-spacing: 0.5em;

    align-items: center;
    color: white;
    display: flex;
    flex-direction: row;
    padding: $default-spacing;

    .level {
      $progress-bar-height: 1.2em;
      $progress-bar-width: 20em;

      align-items: center;
      display: flex;
      flex-direction: row;

      .level-badge {
        align-items: center;
        display: flex;
        flex-direction: column;

        .level-icon {
          background: url(#{$achievement-assets}/level-icon.png) center/contain;
          height: 2.5em;
          width: 2.5em;
        }

        p {
          font-size: 0.9em;
          margin: 0;
        }
      }

      .level-progress {
        display: flex;
        height: $progress-bar-height;
        margin: 0 0 0 $default-spacing;
        width: $progress-bar-width;

        .progress-bar {
          height: $progress-bar-height;
          width: $progress-bar-width;
        }

        p {
          align-items: center;
          color: yellow;
          display: inline-flex;
          justify-content: center;
          margin: 0 0 0 -#{$progress-bar-width};
          width: $progress-bar-width;
          z-index: 1;
        }

        @media only screen and (max-width: 1024px) {
          display: none;
        }
      }

      .milestone {
        border-radius: 0.5em;
        background-color: rgba(0, 0, 0, 0.94);
        box-shadow: 0 0 5px yellow;
        display: flex;
        flex-direction: column;
        margin: 25em 0 0 1.5em;
        padding: 0.5em 2em;
        position: absolute;
        z-index: 2;

        h3 {
          margin: $default-spacing;
          text-align: center;
        }

        .user-xp {
          text-align: center;
          margin-bottom: 1.25em;
        }

        .details {
          align-items: center;
          display: flex;
          flex-direction: row;
          margin: $default-spacing;

          .description {
            margin: 10;
            padding: 0 0 0 $default-spacing;
          }
        }

        .footer {
          color: cyan;
          font-size: 90%;
          font-style: italic;
          margin: $default-spacing;
          text-align: center;
        }
      }
    }

    .level,
    h3 {
      margin: 0 $default-spacing;
    }
  }

  .achievement-manual-editor {
    $default-spacing: 0.5em;

    align-items: center;
    display: flex;
    flex-direction: row;
    padding: $default-spacing;
    color: white;

    input {
      display: inline-flex;
      margin: 0 $default-spacing;
    }

    button {
      background: white;
      display: inline-flex;
      margin: 0 $default-spacing;
    }

    h3 {
      margin: 0 $default-spacing;
      display: inline-flex;
    }
  }

  .achievement-main {
    $border-glow-radius: 10px;
    // Cover aspect ratio 2:1
    $cover-height: 18em;
    $cover-width: 36em;
    // Card aspect ratio 2:1
    $card-height: 5em;
    $card-width: 30em;
    // Default spacing should be at least 10px to prevent border glow bleeding
    $default-spacing: 1em;
    // View aspect ratio 18:25
    $view-height: 50em;
    $view-width: $cover-width;

    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow-y: hidden;

    %container {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-height: $view-height + $default-spacing * 2;
      min-width: max-content;
    }

    .filter-container {
      @extend %container;
      height: 60%;

      .filter {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        margin: auto $default-spacing;

        p {
          font-size: 0.8em;
          margin: 0;
          padding: 1em 0 0 0;
          white-space: nowrap;
        }
      }

      @media only screen and (max-height: 600px) {
        height: 80%;

        p {
          display: none;
        }
      }
    }

    .task-container {
      @extend %container;
      height: 90%;
      list-style-type: none;
      overflow-y: auto;
      padding: 0;
      scrollbar-color: white #444;
      scrollbar-width: thin;

      li {
        margin: $default-spacing;
      }

      li:not(:first-child) {
        margin: 0 $default-spacing $default-spacing;
      }

      .task {
        .achievement-card {
          border-radius: 0.3rem;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          height: $card-height;
          transition: box-shadow 0.2s ease-out;
          width: $card-width;
          padding-bottom: 5.6em;

          .dropdown-button {
            align-items: center;
            display: flex;
            height: $card-height;
            padding: 0 0 0 0.5em;
            width: $card-height;
          }

          .content {
            display: flex;
            flex-direction: column;
            height: $card-height;
            width: $card-width - $card-height;

            .heading {
              align-items: center;
              display: flex;
              flex-direction: row;
              height: math.div($card-height * 3, 5);
            }

            .details {
              display: flex;
              flex-direction: row;
              font-size: 0.8em;

              p {
                margin: 0;
              }

              .ability {
                align-items: center;
                display: flex;
                flex: 1 1 50%;
              }

              .deadline,
              .xp {
                align-items: center;
                display: flex;
                flex: 1 1 25%;

                p {
                  padding: 0 0 0 0.3em;
                }
              }
            }

            .progress {
              margin: 0.2em 0 0 0;
              width: 95%;
            }
          }
        }

        .achievement-card:hover {
          box-shadow: 0 0 10px black;
          transition: box-shadow 0.2s ease-in;
        }

        .prerequisite-container {
          $shrink-factor: 0.9;
          $line-width: 1em;
          // remaining-width is the empty space between line and card
          $remaining-width: math.div((1 - $shrink-factor) * $card-width - 2 * $line-width, 2);
          $top-spacing: 0.2em;
          $line-extension: 0.1em;

          display: flex;
          flex-direction: column;

          .prerequisite {
            display: flex;
            flex-direction: row;
            max-width: $card-width;

            .dropdown-lines {
              height: $top-spacing + $card-height + $remaining-width + $line-extension;
              margin: -#{math.div($card-height, 1.6)} -#{$line-width + $remaining-width} 0
                $line-width;
              min-width: $line-width;
            }

            .achievement-card {
              margin: $top-spacing 0 0 0;
              transform: scale($shrink-factor);
            }
          }

          .prerequisite:first-child {
            .dropdown-lines {
              height: $top-spacing + math.div($card-height, 2) + $remaining-width;
              margin-top: 0;
            }
          }
        }
      }
    }

    .view-container {
      $cover-spacing: 0.3em;
      $content-spacing: 0.5em;

      @extend %container;
      height: 90%;
      min-width: $view-width + $default-spacing * 2;
      overflow-y: auto;
      scrollbar-color: white #444;
      scrollbar-width: thin;

      .no-view {
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        text-shadow: black 1px 1px;
        color: white;
        display: flex;
        flex-direction: column;
        height: $view-height;
        justify-content: center;
        margin: auto;
        width: $view-width;
        border-radius: 1rem;
      }

      .view {
        border-radius: 1rem;
        text-shadow: black 1px 1px;
        color: white;
        display: flex;
        flex-direction: column;
        margin: $default-spacing;
        width: $view-width;
        padding-bottom: 1rem;

        .cover {
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
          display: flex;
          flex-direction: column;
          text-align: center;
          min-height: $cover-height;
          min-width: $cover-width;

          h1 {
            margin: 0.5em auto 0;
            padding: $cover-spacing;
          }

          p {
            margin: 0 auto;
            padding: $cover-spacing;
          }

          .description {
            color: yellow;
            font-size: 0.85em;
            margin: auto auto 1em;
            width: 80%;
          }
        }

        .progress-header {
          margin-left: 2rem;
        }

        .goal {
          align-items: center;
          display: flex;
          flex-direction: row;
          padding: $content-spacing;

          .goal-badge {
            align-items: center;
            display: flex;
            flex-direction: column;
            min-width: 6em;
            padding: 0 $content-spacing;

            .goal-icon {
              background: url(#{$achievement-assets}/xp-icon.png) center/contain;
              height: 2em;
              width: 2em;
            }

            p {
              text-align: center;
              font-size: 0.7em;
              padding: 0.2em 0 0 0;
            }
          }

          .goal-progress {
            width: 100%;
            padding-right: 2.5em;

            .progress {
              margin-top: 5px;
            }
          }

          p {
            margin: 0;
            padding: 0;
          }
        }

        hr {
          background: lightgrey;
          border: none;
          height: 1px;
          margin: $content-spacing auto;
          width: 90%;
        }

        .completion {
          padding: $content-spacing;
          text-align: center;

          h1 {
            color: #fc0;
            margin: 0 auto $content-spacing;
          }

          p {
            margin: 0;
            padding: 0;
          }
        }
      }
    }

    // Scroll Bar
    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 1.5px;
      box-shadow: inset 0 0 3px black;
    }

    ::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 1.5px;
    }
  }
}
