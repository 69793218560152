.githubDialog {
  background-color: $cadet-color-3;
}

.githubDialogHeader {
  background-color: $cadet-color-2;
  color: $cadet-color-4;
}

.githubDialogBody {
  padding-left: 5px;

  h4 {
    margin-top: 0px;
    margin-bottom: 5px;
  }

  li {
    padding-left: 5px;
    margin-bottom: 5px;
  }

  background-color: $cadet-color-3;
  color: $cadet-color-4;
}

.RepositoryRadioGroup {
  color: $cadet-color-4;
  min-height: 100px;
  max-height: 320px;
  height: auto;
  width: auto;
  overflow-y: scroll;
}

.FileTree {
  color: $cadet-color-4;
  min-height: 100px;
  max-height: 320px;
  height: auto;
  width: auto;
  overflow-y: scroll;
}

.github-welcome {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;

  .github-welcome-content {
    padding: 10px 20px 10px 20px;
    display: inline-block;
    margin: 0 0 10px 0;
    width: 80%;
    @include mQ(750px) {
      width: 90%;
    }
  }
}
