div.#{$ns}-dialog.about {
  padding: 0;

  div.#{$ns}-dialog-body {
    margin-top: 10px;
  }

  div.abt {
    margin-top: 10px;
    text-align: justify;
  }
}

div.#{$ns}-dialog.help {
  padding: 0;
  text-align: justify;

  div.#{$ns}-dialog-body {
    margin-top: 15px;
  }

  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 0.5rem;
  }
}

.profile {
  // 30% of 1366 pixels
  min-width: 410px;

  .profile-content {
    height: calc(100vh - 40px);
    flex-grow: 1;
    flex-shrink: 1;
    padding: 20px;
    line-height: 18px;
    display: flex;
    flex-direction: column;

    div.profile-header {
      flex-grow: 0;
      flex-shrink: 0;
      text-align: center;
      padding-bottom: 1.75em;

      div.name {
        font-size: 1.5rem;
        font-weight: 800;
        margin-bottom: 0.3em;
      }

      div.role {
        font-size: 1rem;
        font-style: italic;
      }
    }

    div.profile-placeholder {
      flex: 0 0 auto;
      text-align: center;
    }

    div.profile-progress {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      padding-bottom: 0.75em;
      justify-content: space-around;
      align-items: stretch;

      .profile-xp {
        position: relative;
        width: 42%;
        flex-grow: 0;
        flex-shrink: 0;
        margin-bottom: 0.3em;

        .profile-spinner svg {
          width: 100%;
          height: 100%;
          stroke-width: 6;
          overflow: visible;
        }

        div.type,
        div.total-value,
        div.percentage {
          position: absolute;
          text-align: center;
          width: 100%;
        }

        div.type {
          font-weight: bold;
          font-size: 1.35em;
          top: 35%;
        }

        div.total-value {
          font-size: 1.15em;
          top: 52%;
        }

        div.percentage {
          font-size: 0.95em;
          top: 78%;
        }
      }
    }

    .profile-xp-footer {
      font-style: italic;
      text-align: center;
      padding-bottom: 0.75em;
    }

    div.profile-callouts {
      flex-grow: 1;
      flex-shrink: 1;
      overflow-y: auto;

      .profile-summary-navlink {
        color: black;
        text-decoration: none;

        .profile-summary-callout {
          line-height: 1em;
          padding: 4px 12px 6px 30px;
          border-radius: 6px;

          &:hover {
            background-color: rgba(138, 155, 168, 0.25);
          }

          h4 {
            font-size: 1.1em;
            font-weight: bold;
          }

          .#{$ns}-icon {
            position: absolute;
            top: 6px;
            left: 6px;

            svg {
              height: 18px;
              width: 18px;
            }
          }

          .grade-details,
          .xp-details {
            & > * {
              display: inline-block;
            }

            .title {
              width: 12%;
              text-align: left;
            }

            .value {
              width: 35%;
              text-align: center;
            }

            .value-bar {
              width: 53%;
            }
          }

          & > div:not(:first-of-type) {
            margin-top: 0.2em;
          }
        }
      }

      .profile-summary-navlink:not(:last-child) > .profile-summary-callout {
        margin-bottom: 0.5em;
      }
    }
  }

  .progress-steelblue {
    &.profile-spinner svg path:last-of-type {
      stroke: #137cbd;
    }

    &.value-bar > div {
      background-color: #137cbd;
    }
  }

  .progress-deepskyblue {
    &.profile-spinner svg path:last-of-type {
      stroke: #579ecb;
    }

    &.value-bar > div {
      background-color: #579ecb;
    }
  }

  .progress-skyblue {
    &.profile-spinner svg path:last-of-type {
      stroke: #9ac0d8;
    }

    &.value-bar > div {
      background-color: #9ac0d8;
    }
  }
}

.my-courses {
  .#{$ns}-html-select {
    margin-top: 10px;
  }
}

.create-course {
  .boolean-container {
    display: flex;
  }

  .create-course-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-textarea {
    height: 100px;
  }

  .input-markdown {
    padding: 10px;
    height: 100px;
    background-color: #f5f5f5;
    border-radius: 3px;
    box-shadow: 0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%),
      inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%);
    overflow: auto;
  }

  div.input-markdown > div > * {
    margin: 0;
  }

  .optional-text {
    color: #5c7080;
  }

  .module-help-text-tabs {
    display: inline-block;
    margin-left: 15px;
  }
}

.settings {
  .form-field-help-text {
    margin-left: 10px;
  }
}
