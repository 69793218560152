.Login {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.3)
    ),
    url('#{$images-path}/login_background.jpg');
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-header {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: text-top;

  .#{$ns}-icon {
    margin: 0.1rem 0.5rem 0.5rem 0;
    vertical-align: middle;
  }
}
