.NoPage {
  top: 50px;
  background-size: cover;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.3)
    ),
    url('#{$images-path}/no_page_background.jpg');
  height: 100%;
  width: 100%;
  flex: 1 1 100%;
}
